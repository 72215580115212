export default [
    {
        url: "dataByKlassOrMegama",
        title: "כיתה או מגמה",
        filterFields: ["singleKlass", "singleMegama", "lessonWithAll", "dateRange"],
        isAdmin: false,
        dataSources: ["listening", "conf", "record"],
        reportTypes: ['', "minutes"],
    },
    // {
    //     url: "dataByKlassAndLesson",
    //     title: "כיתה או מגמה ומקצוע",
    //     filterFields: ["singleKlass", "singleMegama", "singleLesson", "singleStudent", "dateRange"],
    //     isAdmin: false,
    //     dataSources: ["listening", "conf", "record", "listeningByDate"],
    // },
    {
        url: "multipleDataByKlassAndLesson",
        title: "כיתה או מגמה ומקצוע",
        filterFields: ["singleKlass", "singleMegama", "lessonWithAll", "singleStudent", "dateRange"],
        isAdmin: false,
        dataSources: ["listening", "conf", "record", "listening_And_conf", "listeningByDate"],
        reportTypes: ['', "percent", "grade", "minutes"],
    },
    {
        url: "diploma",
        title: "הנפקת תעודות לתלמידות",
        filterFields: ["singleKlass", "singleMegama", "lessonWithAll", "singleStudent", "dateRange"],
        isAdmin: false,
        dataSources: ["listening", "conf", "record", "listeningByDate"],
    },
    {
        url: "listeningByKlassPerStudent",
        title: "דוח כללי לכיתה",
        filterFields: ["singleKlass", "dateRange"],
        isAdmin: false,
    },
];
