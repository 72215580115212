export default [
    {
        url: "lesson",
        title: "העלאת נתוני שיעורים",
        isFromYemot: false,
    },
    {
        url: "student",
        title: "העלאת נתוני תלמידות",
        isFromYemot: false,
    },
    {
        url: "listening",
        title: "העלאת נתוני האזנה",
        isFromYemot: false,
    },
];
