export default [
    {
        url: "listening",
        title: "רשימת קבצי האזנה",
    },
    {
        url: "conf",
        title: "רשימת קבצי ועידה",
    },
    {
        url: "record",
        title: "רשימת קבצי הקלטות",
    },
];
