export const dataSourceMapping = {
    listening: "האזנה",
    conf: "ועידה",
    record: "שיעורים מוקלטים",
    listening_And_conf: "משולב האזנה וועידה",
    listeningByDate: "האזנה לפי תאריך",
};

export const reportTypeMapping = {
    ['']: "רגיל",
    percent: "אחוזים",
    grade: "ציונים",
    minutes: "דקות",
}